import $ from "jquery";
window.jQuery = $;
window.$ = $;

$(document).ready(function () {
  if ($("#map_svg").length) {
    var currentRegion = JSON.parse(jsMap.pays);
    var currentHub = JSON.parse(jsMap.hubs);
    var currentPartnership = JSON.parse(jsMap.partnerships);
    var markers = [];

    for (var hub of currentHub) {
      markers.push({
        latLng: hub.latlng ? hub.latlng.split(",") : [0, 0],
        name: hub.name,
        style: {
          fill: "#efac14",
          stroke: "#efac14",
        },
      });
    }

    for (var hub of currentPartnership) {
      markers.push({
        latLng: hub.latlng ? hub.latlng.split(",") : [0, 0],
        name: hub.name,
        style: {
          fill: "#AC0973",
          stroke: "#AC0973",
        },
      });
    }

    $("#map_svg").vectorMap({
      map: "world_mill_en",
      regionsSelectable: true,
      panOnDrag: false,
      zoomOnScroll: false,
      zoomOnScrollSpeed: false,
      zoomAnimate: false,
      enableZoom: true,
      hoverColor: null,
      backgroundColor: "transparent",
      focusOn: {
        x: 0.5,
        y: 0.5,
        scale: 1,
        animate: false,
      },
      regionStyle: {
        initial: {
          fill: "#374a66",
          "fill-opacity": 1,
          stroke: "#374a66",
          "stroke-width": 0.1,
          "stroke-opacity": 0,
        },
        hover: {
          "fill-opacity": 0.8,
          cursor: "pointer",
        },
      },
      series: {
        regions: [
          {
            scale: ["#00aeff"],
            normalizeFunction: "polynomial",
          },
        ],
      },
      markers: markers,
      onRegionClick: function (event, code, region) {
        if (currentRegion.indexOf(code) > -1) {
          event.preventDefault();
          highlight_lang(code);
          $("#country_name_" + code).trigger("click");
        } else {
          event.preventDefault();
        }
      },
      onRegionOver: function (event, code, region) {
        if (currentRegion.indexOf(code) > -1) {
          $(".jvectormap-region[data-code = " + code + "]").addClass(
            "currentRegion"
          );
        } else {
          event.preventDefault();
        }
      },
    });

    for (var prop in currentRegion) {
      var ss = currentRegion[prop];
      $(".jvectormap-region[data-code = " + ss + "]").css({
        fill: "#00aeff",
        stroke: "#00aeff",
      });
      $(".jvectormap-region[data-code = " + ss + "]").addClass("currentRegion");
    }

    function highlight_lang(code) {
      var map = $("#map_svg").vectorMap("get", "mapObject");
      map.clearSelectedRegions();
      //clear the array values
      // map.series.regions[0].clear();
      // map.setSelectedRegions([code]);
      $(".jvectormap-region").css({
        fill: "#374a66",
        stroke: "#374a66",
      });
      $(".jvectormap-region[data-code = " + code + "]").css({
        fill: "#00aeff",
        stroke: "#00aeff",
      });
    }

    var obj = $("#map_svg").vectorMap("get", "mapObject");

    $(".country-name").click(function () {
      var idCountry;
      if ($(this).hasClass(".country-name-active")) {
        idCountry = "";
        $(".country-name").removeClass("country-name-active");
        $(".desc-map").removeClass("desc-map-active");
        $(".info-country").removeClass("active-info");
      } else {
        idCountry = $(this).attr("id");
        $(".country-name").removeClass("country-name-active");
        $(".desc-map").removeClass("desc-map-active");
        $(".info-country").removeClass("active-info");

        $(this).addClass("country-name-active");
        $(this).parents(".desc-map").addClass("desc-map-active");
        $(".info-country[data-id = " + idCountry + "]").addClass("active-info");
        $(".jvectormap-region").css({
          fill: "#374a66",
          stroke: "#374a66",
        });

        var CN = $(this).attr("data-name-id");

        $(".jvectormap-region[data-code = " + CN + "]").css({
          fill: "#00aeff",
          stroke: "#00aeff",
        });
      }
    });

    $(".close-info-country").click(function () {
      $(".country-name").removeClass("country-name-active");
      $(".desc-map").removeClass("desc-map-active");
      $(".info-country").removeClass("active-info");
      obj.clearSelectedRegions();
      for (var prop in currentRegion) {
        var ss = currentRegion[prop];
        if (ss === "FR") {
          $(".jvectormap-region[data-code = " + ss + "]").css({
            fill: "#00aeff",
            stroke: "#00aeff",
          });
          $('.jvectormap-region[data-code="FR-RE"]').css({
            fill: "#00aeff",
            stroke: "#00aeff",
          });
        } else {
          $(".jvectormap-region[data-code = " + ss + "]").css({
            fill: "#00aeff",
            stroke: "#00aeff",
          });
        }
      }
    });
  }
});
